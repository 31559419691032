import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const DottedLine = ({ style }) => {
    const data = useStaticQuery(graphql`
    query {
        dottedLine: file(relativePath: { eq: "studio/dotted_line.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
    return <Img style={style}
        fluid={data.dottedLine.childImageSharp.fluid} alt="White dotted line"
    />
}

export default DottedLine