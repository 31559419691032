import React, { Component } from "react"
import { TimelineLite, Linear } from 'gsap'
import Styled from "styled-components"
import WatermarkImage from "./watermark_image"

const Title = Styled.h2`
position: absolute;
font-size: 1.3em;
top: 50%;
left: 45%;
transform: translate(-50%, -50%);
font-weight: bold;
span{
    font-weight: normal;
    -webkit-text-fill-color: #ddd;
    -webkit-text-stroke: 1px white;
}
`

class WatermarkAnimation extends Component {
    componentDidMount() {

        let timeline = new TimelineLite();
        timeline.to('#watermark', 10, { rotation: 360, repeat: -1, transformOrigin: '50% 50%', ease: Linear.easeNone })

    }
    render() {
        return (
            <div style={{ textAlign: `center`, position: `relative`, width: `300px`, margin: `auto`}}>
                <div id="watermark" style={{ width: `90%` }}>
                    <WatermarkImage />
                </div>
                <Title>
                    HAG's<span>FRAMEWORK</span>
                </Title>
            </div>
        )
    }
}

export default WatermarkAnimation