import React from 'react'
import Lottie from "lottie-react"
import lottieJson from '../../lottie/animation_khw3ebvn.json'

const interactivity = {
  mode: "scroll",
  actions: [
    {
      visibility: [0, 0.2],
      type: "stop",
      frames: [0],
    },
    {
      visibility: [0.2, 0.45],
      type: "seek",
      frames: [0, 50],
    },
  ],
}

const Plants = () => {
  return (
      <Lottie
      animationData={lottieJson}
      interactivity={interactivity}
      />
  )
}

export default Plants;