import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const CyberBrain = ({ style }) => {
    const data = useStaticQuery(graphql`
    query {
        cyberBrain: file(relativePath: { eq: "studio/cyber_brain.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
    return <Img style={style}
        fluid={data.cyberBrain.childImageSharp.fluid} alt="Natural and artificial brains"
    />
}

export default CyberBrain