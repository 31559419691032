import React from "react"
import { Row, Col } from "react-bootstrap"
import WatermarkAnimation from "../components/watermark_animation"
import Styled from "styled-components"

const FrameworkContainer = Styled(Row)`
@media(min-width: 900px){
    padding: 10vw;
}
@media(max-width: 900px){
    margin-bottom: 2em;
    .watermarkAnimation{
        padding-left: 10vw;
    }
}
`

const Framework = ({ image }) => (
    <FrameworkContainer className="align-items-center">
        <Col sm="12" md="3" className="watermarkAnimation">
            <WatermarkAnimation />
        </Col>
        <Col sm="12" md={{ span: 7, offset: 2 }}>
            {image}
        </Col>
    </FrameworkContainer>
)

export default Framework