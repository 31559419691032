import React, { useRef, useEffect } from 'react'
import Lottie from "lottie-react"
import lottieJson from '../../lottie/column_chart4.json'

const ColumnChart = () => {
  const lottieRef = useRef();
  useEffect(() => {
    lottieRef.current.setSpeed(1.5);
  }
  );
  return (
    <div>
      <Lottie
        animationData={lottieJson}
        style={{ height: 300 }}
        lottieRef={lottieRef}
      />
    </div>
  )
};
export default ColumnChart;