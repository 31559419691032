import React from 'react'
import Lottie from "lottie-react"
import lottieJson from '../../lottie/28938-background-7.json'

const YellowBlackBackground = () => {
  return (
    <Lottie
      animationData={lottieJson}
    />
  )
}

export default YellowBlackBackground;