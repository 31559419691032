import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const StudioFramework = ({ style }) => {
    const data = useStaticQuery(graphql `
    query {
          studioFramework: file(relativePath: { eq: "studio/studio_framework.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
    return <Img style = { style }
    fluid = { data.studioFramework.childImageSharp.fluid } alt="HAG Startup Program"
    />
}

export default StudioFramework