import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const WatermarkImage = ({ style }) => {
  const data = useStaticQuery(graphql`
    query {
      watermarkImage: file(relativePath: { eq: "watermark.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
  return <Img style={style}
    fluid={data.watermarkImage.childImageSharp.fluid} alt="HAG framework watermark"
  />
}

export default WatermarkImage