import React from "react"
import Layout from "../components/layout"
import Menu from "../components/menu"
import VerticalTitle from "../components/verticaltitle"
import Styled from "styled-components"
import Body from "../components/body"
import { Row, Col, Button } from "react-bootstrap"
import Framework from "../components/framework"
import StudioFramework from "../components/studio-images/studio_framework"
import Form from "../components/form"
import RSSStudio from "../components/rss_studio"
import YellowBlackBackground from "../components/lottie-components/yellow_black_background"
import Footer from "../components/footer"
import Highlight from "../components/highlight"
import Arrow from "../components/lottie-components/arrow"
import Ring from "../components/studio-images/ring"
import Rocket from "../components/studio-images/rocket"
import Lamp from "../components/studio-images/lamp"
import Target from "../components/studio-images/target"
import DottedLine from "../components/studio-images/dotted_line"
import Particles from "../components/studio-images/particles"
import CyberBrain from "../components/studio-images/cyber_brain"
import ColumnChart from "../components/lottie-components/column_chart"
import Plants from "../components/lottie-components/plants"
import LazyLoad from 'react-lazyload'

const Programs = Styled.div`
padding: 10vw;
text-align: center;
.btn-group-vertical{
  border: 1px solid black;
  border-radius: 5px;
}
button{
  border: none;
  background-color: #ccc;
}
button:hover{
  background-color: #ccc;
}
p{
  text-align: justify;
  font-size: 1em;
}
`

const LoginButton = Styled(Button)`
background-color: #EEE655;
border-radius: 20px;
border: none;
color: black !important;
width: 10em;
:hover{
  background-color: #D4CC3B;
}
:focus{
  outline: none;
  border: none;
  box-shadow: none !important;
  background-color: #D4CC3B !important;
}
:active{
  background-color: #D4CC3B !important;
}
`

const Login = Styled(Row)`
text-align: center;
`

const GrowTitle = Styled.div`
position: absolute;
margin-top: 8em;
z-index: 1;
-webkit-text-fill-color: white;
-webkit-text-stroke: 2px black;
h2{
  font-size: 4em;
}
`

const ProgramButton = Styled(Button)`
background-color: #485696 !important;
border: none;
width: 50%;
position: absolute;
margin-right: auto;
margin-left: auto;
right: 0;
left: 0;
bottom: 0px;
`

const IndexPage = () => (
  <Layout style={{ backgroundColor: `black`, color: `#ddd` }}
    lang="en" title="HAG Studio" link="https://www.hag.studio" description="HAG Studio is a global venture builder that creates impact by building startups together with entrepreneurs.">
    <Menu
      MenuItem3={
        <Body>
          <Highlight>
            <Login>
              <Col sm="12" md={{ offset: 8, span: 4 }}>
                <p>Are you member of HAG Network?</p>
                <LoginButton href="https://portal.hag.network/client" target="blank">Login</LoginButton>
              </Col>
            </Login>
            <Row style={{ paddingRight: `5vw` }}>
              <Col sm="12" md="2">
                <VerticalTitle style={{ fontWeight: `bold`, color: `#EEE655` }}>
                  studio
            </VerticalTitle>
              </Col>
              <Col sm="12" md="10" style={{ padding: `10vw` }}>
                <p>At HAG Studio, we are passionate about venture building and creating <span className="text-highlight-yellow">tech-based</span> and impact-driven startups.</p>
                <p>We know that creating a startup is not easy.</p>
                <p>That’s why we invest our time, effort, expertise and financial capital to help our clients stay away from the <span className="text-highlight-yellow">death valley</span> and fulfil their vision.</p>
              </Col>
            </Row>
            <Row style={{ backgroundColor: `#485696`, padding: `10vw`, minHeight: `100vh` }}>
              <h2><b>WHY BUILD YOUR STARTUP WITH <span style={{ color: `#EEE655` }}>HAG</span>?</b></h2>
              <Col sm="12" md="6">
                <p>Even though we are a company that helps entrepreneurs, corporates or investors build new startups, HAG Studio is not your average <span className="text-highlight-yellow">acceleration</span> program.</p>
              </Col>
              <Col sm="12" md="6">
                <LazyLoad offset={100}>
                  <Arrow />
                </LazyLoad>
              </Col>
            </Row>
            <div style={{ padding: `10vw`, minHeight: `100vh` }}>
              <div style={{ position: `relative`, zIndex: `2` }}>
                <h2><b>HERE’S WHY</b></h2>
                <p>We strike on the balance between customer-centred and profit-driven.</p>
                <p>HAG Studio is a <span className="text-highlight-yellow">business design</span> program that puts customers at the centre of its approach and crafts profitable <b>business models</b> at the same time.</p>
                <p>Because of our approach, we are revolutionising the way <span className="text-highlight-yellow">startups</span> are created and managed.</p>
              </div>
              <Ring style={{ position: `relative`, width: `40vh`, zIndex: `0`, marginTop: `-50vh`, float: `right` }} />
            </div>
            <div>
              <Row>
                <Col md="6" sm="12" style={{ paddingLeft: `10vw`, minHeight: `100vh` }} className="align-items-center">
                  <h2><b>WE GUIDE FROM THE GROUND UP.</b></h2>
                  <p>Whereas accelerators provide support for  external teams and companies for only  a short while, we become involved in  the project right from the <span className="text-highlight-yellow">early stages.</span></p>
                  <p>We’ll help you identify the  needs of your potential  customers and support  you through your <span className="text-highlight-yellow">idea stage.</span></p>
                </Col>
                <Col md="6" sm="12" style={{ padding: `0` }}>
                  <Rocket style={{ width: `70vh`, position: `relative`, float: `right` }} />
                </Col>
              </Row>
            </div>
            <Lamp>
              <div style={{ padding: `10vw` }}>
                <p>We won’t stop there, either.</p>
                <p>We’ll guide you along the journey until you’re in the process of <b>scaling</b> your business.</p>
                <p>We have the resources to support your growth.</p>
                <p>Our early intervention can benefit your startup in the following crucial processes:</p>
              </div>
            </Lamp>
            <Row style={{ backgroundColor: `#485696`, padding: `10vw`, minHeight: `100vh` }}>
              <h2 style={{ marginBottom: `3em` }}><b>IDEATION, PROBLEM VALIDATION AND BUSINESS DESIGN</b></h2>
              <Col sm="12" md="6">
                <p style={{ position: `relative`, zIndex: `1` }}>Together, we will generate fresh ideas addressing the customer problem you aim to solve, and we’ll verify that there is a real demand for your solution.</p>
                <Target style={{ position: `absolute`, width: `80%`, marginTop: `-15em`, zIndex: `0` }} />
              </Col>
              <Col sm="12" md="6">
                <p>As you develop your business model with us, you’ll find the best way to reduce risks, minimise costs and speed up delivery – all while keeping your future customers in mind.</p>
              </Col>
            </Row>
            <div style={{ paddingBottom: `10vw` }}>
              <div style={{ paddingRight: `10vw`, paddingLeft: `10vw`, paddingTop: `10vw` }}>
                <h2><b>MANAGEMENT AND OPERATIONS</b></h2>
                <p>Whether you need support for your legal, accounting or human resources units, we’re here to ensure that your management is <span className="text-highlight-yellow">seamless.</span></p>
              </div>
              <div style={{ width: `100%`, height: `15em` }}>
                <DottedLine style={{ width: `35em`, float: `right` }} />
              </div>
            </div>
            <Particles>
              <div style={{ padding: `10vw` }}>
                <h2><b>PRODUCT-MARKET FIT</b></h2>
                <p>We’ll help you achieve product-market fit through our wealth of experience working in the industry, our proprietary tools, input from our highly qualified partners, and our extensive <b>network.</b></p>
              </div>
            </Particles>
            <div style={{ padding: `10vw`, minHeight: `100vh` }}>
              <h2><b>DEVELOPMENT AND BRANDING</b></h2>
              <p>Our in-house developers, outsourced services and effective talent acquisition will reinforce your development,<span className="text-highlight-yellow">branding</span> and marketing endeavours.</p>
              <Row>
                <Col sm="12" md={{ span: 6, offset: 6 }}>
                  <CyberBrain style={{ width: `20em` }} />
                </Col>
              </Row>
            </div>
            <div style={{ backgroundColor: `#485696`, padding: `10vw`, minHeight: `100vh` }}>
              <h2><b>INVESTMENT</b></h2>
              <p>We’ll make sure that you have access to industry experts in your specific domain.</p>
              <Row className="align-items-center">
                <Col sm="12" md="6">
                  <p>And with our studio fund and active investor network, we are prepared to set you up for <span className="text-highlight-yellow">success.</span></p>
                </Col>
                <Col sm="12" md="6">
                  <LazyLoad offset={100}>
                    <ColumnChart />
                  </LazyLoad>
                </Col>
              </Row>
            </div>
            <div style={{ padding: `10vw` }}>
              <h2><b>BEST PRACTICES</b></h2>
              <p>Many of the tasks required in the formation and running of an early-stage company can be distracting and bog down even the best of <span className="text-highlight-yellow">entrepreneurs.</span></p>
              <p>We’ll impart best practices to help you sustainably achieve growth.</p>
              <LazyLoad offset={100} height={`20vh`}>
                <Plants />
              </LazyLoad>
            </div>
            <Row className="align-items-center" style={{ padding: `10vw` }}>
              <Col sm="12" md="6">
                <GrowTitle>
                  <h2>GROW <br /> WITH US</h2>
                </GrowTitle>
                <div style={{ position: `relative`, zIndex: `0` }}>
                  <LazyLoad offset={100}>
                    <YellowBlackBackground />
                  </LazyLoad>
                </div>
              </Col>
              <Col sm="12" md="6">
                <p>At HAG Studio, we help startups and founders develop <span className="text-highlight-yellow">innovative</span> and scalable businesses through strong connections and reliable methodologies.</p>
                <p>We believe that by connecting your <span className="text-highlight-yellow">startup</span> to the right people and the right resources, we can create an ideal environment for long-term growth and high efficiency.</p>
                <p>Ready to grow your <b>startup</b>? Get in touch with us today.</p>
              </Col>
            </Row>
            <Programs>
              <h2 style={{ textAlign: `center`, color: `#EEE655` }}><b>PROGRAMAS HAG STUDIO</b></h2>
              <p style={{ textAlign: `center`, marginBottom: `4em` }}>
                Inspirados no tema espacial, nossos programas são dividos em três etapas, assim como no lançamento de um foguete.</p>
              <Row>
                <Col sm="12" md="4">
                  <h4 style={{ marginBottom: `1em` }}>Etapa 1: <br /> HAG Ignition</h4>
                  <p>Entenda todos os conceitos fundamentais para estruturar sua ideia como um negócio.
                  Aprenda sobre como explorar oportunidades de mercado, como segmentar um público, como desenvolver uma proposta de valor e como converter isso em um modelo de negócios coeso.
                </p>
                  <ProgramButton href="https://comunidade.hag.studio/courses" target="blank">Saiba Mais</ProgramButton>
                </Col>
                <Col sm="12" md="4">
                  <h4 style={{ marginBottom: `1em` }}>Etapa 2: <br /> HAG Lift-Off</h4>
                  <p>Ache parceiros de negócio, descubra como estruturar seu produto, construa um MVP e comece a buscar clientes.
                  Tenha acesso a nossa rede de contatos, parceiros, ferramentas para desenvolver seu produto e marketing, além de sessões de mentoria com profissionais da HAG.
                </p>
                  <ProgramButton href="#" disabled>Em breve</ProgramButton>
                </Col>
                <Col sm="12" md="4">
                  <h4 style={{ marginBottom: `1em` }}>Etapa 3: <br /> HAG Apogee</h4>
                  <p style={{ marginBottom: `3em` }}>Transforme feedbacks em uma timeline de desenvolvimento, estruture marketing, analytics, incorpore sua empresa e entenda como captar recursos com um investidor.
                  Tenha acesso ao investidores da nossa rede, todos os parceiros HAG e Sprints semanais guiados por um especialista HAG.
                </p>
                  <ProgramButton href="https://gust.com" target="blank">Saiba Mais</ProgramButton>
                </Col>
              </Row>
            </Programs>
            <LazyLoad offset={100} height={`100vh`}>
              <Framework
                image={
                  <StudioFramework />
                }
              ></Framework>
            </LazyLoad>
            <RSSStudio />
            <Form />
            <Footer />
          </Highlight>
        </Body>
      }
      Titles={["about", "services", "academy"]}
      Links={["https://www.hag.ventures", "https://www.hag.ventures/about", "https://www.hag.services", "https://www.hag.academy"]}
    ></Menu>
  </Layout>
)

export default IndexPage
