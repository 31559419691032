import { useLottie, useLottieInteractivity } from "lottie-react"
import lottieJson from '../../lottie/animation_khumlm31.json'

const options = {
    animationData: lottieJson,
};

const Arrow = () => {
    const lottieObj = useLottie(options);
    const Animation = useLottieInteractivity({
        lottieObj,
        mode: "scroll",
        height: "100",
        actions: [
            {
                visibility: [0.4, 0.9],
                type: "seek",
                frames: [0, 220],
            },
        ],
    });
    return Animation;
};

export default Arrow;