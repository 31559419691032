import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Rocket = ({ style }) => {
    const data = useStaticQuery(graphql `
    query {
          rocket: file(relativePath: { eq: "studio/rocket.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
    return <Img style = { style }
    fluid = { data.rocket.childImageSharp.fluid } alt="Rocket launch"
    />
}

export default Rocket